


































































































































































































































































































































































































import MNotificationVue from "@/mixins/MNotification.vue";
import {
  DATE_TIME_HOURS_DEFAULT_FORMAT,
  DEFAULT_DATE_FORMAT,
} from "@/models/constants/date.constant";
import { Mode } from "@/models/enums/global.enum";
import { Messages } from "@/models/enums/messages.enum";
import {
  AddressDataList,
  ContactData,
} from "@/models/interface/contact.interface";
import {
  CreateAccessories,
  DataDeliveryOrderLines,
  RequestDo,
} from "@/models/interface/deliveryorder.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import {
  SalesOrderLineResponseDto,
  SalesOrderResponseDto,
} from "@/models/interface/sales-order";
import { PickingListData } from "@/models/interface/salesOrder.interface";
import { ColumnTableCustom } from "@/models/interface/util.interfase";
import { contactServices } from "@/services/contact.service";
import { deliveryOrderServices } from "@/services/deliveryorder.service";
import { logisticServices } from "@/services/logistic.service";
import { salesOrderServices } from "@/services/salesorder.service";
import {
  changeCurrencytoNumeric,
  currencyFormat,
} from "@/validator/globalvalidator";
import { WrappedFormUtils } from "ant-design-vue/types/form/form";
import moment from "moment";
import printJs from "print-js";
import { Component, Vue } from "vue-property-decorator";

interface DataListItems {
  no: string;
  key: number;
  productCode: string;
  productName: string;
  merk: string;
  location: string;
  qty: string;
  qtyPick: string;
  // qtyAvailable: string;
  uom: string;
  listAccessories?: string;
  modalListAccessories: string[];
  dataListItemsModalValue?: any;
  id?: string;
  disabledInput?: boolean;
  disabledCheckBox?: boolean;
  serialNumber: string;
  type: string;
  customerLocation: string;
}
@Component({
  mixins: [MNotificationVue],
})
export default class DeliveryOrder extends Vue {
  DEFAULT_DATE_FORMAT = DEFAULT_DATE_FORMAT;
  DATE_TIME_HOURS_DEFAULT_FORMAT = DATE_TIME_HOURS_DEFAULT_FORMAT;
  totalElements = 0 as number;
  page = 0 as number;
  moment = moment;
  rentDate = [] as any;
  typemodal = "" as string;
  dataListItemsModal = [] as any[];
  dataInitListItemsModal = [] as any[];
  limit = 10 as number;
  indexRow = 0 as number;
  disabledData = false as boolean;
  isFormSubmitted = false as boolean;
  disabledPrint = true as boolean;
  search = "" as string;
  mode = "" as string;
  dataListSupplierAddressBillTo = [] as AddressDataList[];
  dataListSupplier = [] as ContactData[];
  journalNumber = {
    name: "" as string,
    id: "" as string,
  };
  columnsTable = [
    {
      title: this.$t("lbl_no"),
      dataIndex: "no",
      key: "no",
      width: 60,
      scopedSlots: { customRender: "isNull" },
      responsiveColInput: [
        {
          name: "qtyPick",
          placeholder: "QTY Pick",
          style: "width: 100%;",
        },
        {
          name: "qty",
          placeholder: "QTY",
          style: "width: 100%;",
          disabled: true,
        },
      ],
    },
    {
      title: this.$t("lbl_part_number_unit_code"),
      dataIndex: "productCode",
      key: "productCode",
      width: 250,
      scopedSlots: { customRender: "isNull" },
    },
    {
      title: this.$t("lbl_part_name"),
      dataIndex: "productName",
      key: "productName",
      width: 200,
      scopedSlots: { customRender: "isNull" },
    },
    {
      title: this.$t("lbl_serial_number"),
      dataIndex: "serialNumber",
      key: "serialNumber",
      width: 200,
      scopedSlots: { customRender: "isNull" },
    },
    {
      title: this.$t("lbl_type"),
      dataIndex: "type",
      key: "type",
      width: 200,
      scopedSlots: { customRender: "isNull" },
    },
    {
      title: this.$t("lbl_customer_location"),
      dataIndex: "customerLocation",
      key: "customerLocation",
      width: 300,
      scopedSlots: { customRender: "isNull" },
    },
    {
      title: this.$t("lbl_merk"),
      dataIndex: "merk",
      key: "merk",
      width: 200,
      scopedSlots: { customRender: "isNull" },
    },
    {
      title: this.$t("lbl_location"),
      dataIndex: "location",
      key: "location",
      width: 300,
      scopedSlots: { customRender: "isNull" },
    },
    {
      title: this.$t("lbl_qty"),
      dataIndex: "qty",
      key: "qty",
      width: 200,
      scopedSlots: { customRender: "qty" },
    },
    {
      title: this.$t("lbl_qty_pick"),
      dataIndex: "qtyPick",
      key: "qtyPick",
      width: 200,
      scopedSlots: { customRender: "qtyPick" },
    },
    {
      title: this.$t("lbl_uom"),
      dataIndex: "uom",
      key: "uom",
      width: 200,
      scopedSlots: { customRender: "isNull" },
    },
    {
      title: this.$t("lbl_list_accessories"),
      dataIndex: "listAccessories",
      key: "listAccessories",
      width: 250,
      scopedSlots: { customRender: "buttonAdd" },
    },
  ] as ColumnTableCustom[];
  columnsTableModal = [
    {
      title: this.$t("lbl_no"),
      dataIndex: "no",
      key: "no",
      width: 30,
      scopedSlots: { customRender: "isNull" },
      responsiveColSelectAddNew: [
        {
          name: "listAccessories",
          placeholder: this.$t("lbl_list_accessories"),
          style: "width: 100%;",
          disabled: false,
          value: "id",
          options: [],
        },
      ],
      responsiveColCheckBox: [
        {
          name: "exist",
          style: "width: 100%;",
          disabled: "",
        },
      ],
    },
    {
      title: this.$t("lbl_list_accessories"),
      dataIndex: "listAccessories",
      key: "listAccessories",
      width: 100,
      scopedSlots: { customRender: "listAccessories" },
    },
    {
      title: this.$t("lbl_exist"),
      dataIndex: "exist",
      key: "exist",
      width: 30,
      scopedSlots: { customRender: "exist" },
    },
  ] as ColumnTableCustom[];
  form!: WrappedFormUtils;
  selectedRowKeys = [] as number[];

  formRules = {
    pickingListNumber: {
      label: "lbl_picking_list_no",
      name: "pickingListNumber",
      placeholder: "lbl_picking_list_no_placeholder",
      decorator: [
        "pickingListNumber",
        {
          rules: [
            {
              required: false,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    salesOrderNumber: {
      label: "lbl_sales_order_no",
      name: "salesOrderNumber",
      placeholder: "lbl_sales_order_no_placeholder",
      decorator: [
        "salesOrderNumber",
        {
          rules: [
            {
              required: false,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    workOrderNumber: {
      label: "lbl_work_order_number",
      name: "workOrderNumber",
      placeholder: "lbl_work_order_number_placeholder",
      decorator: [
        "workOrderNumber",
        {
          rules: [
            {
              required: false,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    icNumber: {
      label: "lbl_ic_number",
      name: "lbl_ic_number",
      placeholder: "lbl_ic_number",
      decorator: ["icNumber"],
    },
    deliveryOrderNumber: {
      label: "lbl_delivery_order_number",
      name: "deliveryOrderNumber",
      placeholder: "lbl_delivery_order_number_placeholder",
      decorator: [
        "deliveryOrderNumber",
        {
          rules: [
            {
              required: false,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    deliveryOrderDate: {
      label: "lbl_delivery_order_date",
      name: "deliveryOrderDate",
      placeholder: "lbl_delivery_order_date_placeholder",
      decorator: [
        "deliveryOrderDate",
        {
          rules: [
            {
              required: false,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    rentPeriod: {
      label: "Rent Period",
      name: "rentPeriod",
      placeholder: "Insert Rent Period",
      decorator: [
        "rentPeriod",
        {
          rules: [
            {
              required: false,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    supplierExpedition: {
      label: "lbl_expedition_name",
      name: "supplierExpedition",
      placeholder: "lbl_expedition_name_placeholder",
      decorator: [
        "supplierExpedition",
        {
          rules: [
            {
              required: true,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    supplierAddressBillTo: {
      label: "lbl_expedition_address",
      name: "supplierAddressBillTo",
      placeholder: "lbl_expedition_address_placeholder",
      decorator: [
        "supplierAddressBillTo",
        {
          rules: [
            {
              required: true,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    truckNumber: {
      label: "lbl_truck_number",
      name: "truckNumber",
      placeholder: "lbl_truck_number_placeholder",
      decorator: [
        "truckNumber",
        {
          rules: [
            {
              required: true,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    driverName: {
      label: "lbl_driver_name_and_phone_number",
      name: "driverName",
      placeholder: "lbl_driver_name_and_phone_number_placeholder",
      decorator: [
        "driverName",
        {
          rules: [
            {
              required: true,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    journalNumber: {
      label: "lbl_journal_number",
      name: "journalNumber",
      placeholder: "lbl_journal_number_placeholder",
      decorator: [
        "journalNumber",
        {
          rules: [
            {
              required: false,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    customerName: {
      label: "lbl_customer_name",
      name: "customerName",
      placeholder: "lbl_customer_name_placeholder",
      decorator: [
        "customerName",
        {
          rules: [
            {
              required: false,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    customerAddressBillTo: {
      label: "lbl_customer_address_bill_to",
      name: "customerAddressBillTo",
      placeholder: "lbl_customer_address_bill_to_placeholder",
      decorator: [
        "customerAddressBillTo",
        {
          rules: [
            {
              required: false,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    customerAddressShipTo: {
      label: "lbl_customer_address_ship_to",
      name: "customerAddressShipTo",
      placeholder: "lbl_customer_address_ship_to_placeholder",
      decorator: [
        "customerAddressShipTo",
        {
          rules: [
            {
              required: false,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    phoneNumber: {
      label: "lbl_phone_number",
      name: "phoneNumber",
      placeholder: "lbl_phone_placeholder",
      decorator: [
        "phoneNumber",
        {
          rules: [
            {
              required: true,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    customerPicName: {
      label: "lbl_customer_pic_name",
      name: "customerPicName",
      placeholder: "lbl_customer_pic_name_placeholder",
      decorator: [
        "customerPicName",
        {
          rules: [
            {
              required: true,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    description: {
      label: "lbl_note",
      name: "description",
      placeholder: "lbl_note_placeholder",
      decorator: [
        "description",
        {
          rules: [
            {
              required: true,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
  };
  dataListItems = [] as DataListItems[];
  dataListPickingList = [] as PickingListData[];
  modalOpen = false as boolean;
  showNumber = "" as string;
  idDo = "" as string;
  dataTemp = {
    customerId: "" as string,
    dataId: "" as string,
    pickingListId: "" as string,
  };
  loading = {
    pickingList: false as boolean,
    supplierExpedition: false as boolean,
    supplierAddres: false as boolean,
    tableModal: false as boolean,
    isFormSubmitted: false as boolean,
    supplier: false as boolean,
    print: false as boolean,
    createAcc: false,
  };
  inputAccessories = "";
  detailSO = {} as SalesOrderResponseDto;

  handleAddData(response) {
    this.indexRow = response.data.key;
    this.dataInitListItemsModal.forEach(item => {
      item.exist = false;
    });
    this.dataListItemsModal = this.dataInitListItemsModal;
    if (this.dataListItems[response.data.key].modalListAccessories.length > 0) {
      this.dataListItems[response.data.key].modalListAccessories.forEach(
        value => {
          for (const i of this.dataListItemsModal) {
            if (i.listAccessories === value) {
              i.exist = true;
            }
          }
        }
      );
    }
    this.modalOpen = true;
  }

  saveDataModal() {
    this.modalOpen = false;
    let dataTempListAccessories = [] as string[];
    this.dataListItemsModal.forEach(item => {
      if (item.exist) {
        dataTempListAccessories.push(item.listAccessories);
      }
    });

    this.dataListItems[this.indexRow].modalListAccessories =
      dataTempListAccessories;
    if (dataTempListAccessories.length > 0) {
      this.dataListItems[this.indexRow].listAccessories =
        dataTempListAccessories[0];
    } else {
      this.dataListItems[this.indexRow].listAccessories = "";
    }
  }

  handleBack(): void {
    this.$confirm({
      title: this.$t("lbl_leave_page"),
      onOk: () => this.backNav(),
      onCancel() {
        return;
      },
    });
  }

  backNav(): void {
    const from = this.$route.query.from;
    if (from && from === "dashboard") {
      this.$router.push({ name: "dashboard" });
    } else {
      this.$router.push({ name: "sales.delivery-order" });
    }
  }

  print() {
    this.loading.print = true;
    deliveryOrderServices
      .getPrintDeliveryOrder(this.idDo)
      .then(dataBlob => {
        if (dataBlob) {
          const url = window.URL.createObjectURL(new Blob([dataBlob]));
          printJs(url);
        }
      })
      .finally(() => (this.loading.print = false));
  }
  onRangePickerChange(dates: Array<any>): void {
    if (!dates.length) {
      this.rentDate = undefined;
    }
  }
  handleInput(
    value,
    key,
    objectColInput,
    _objectColInputName: string,
    onEvt: string
  ) {
    let formatedVal: string | number = value;

    if (onEvt === "onBlur") {
      formatedVal = currencyFormat(value);
    } else if (onEvt === "onFocus") {
      formatedVal = changeCurrencytoNumeric(value);
    }
    this.dataListItems[key][objectColInput.name] = formatedVal;
    this.dataListItems = this.dataListItems.slice();
  }

  setDataForm(values): void {
    for (const key in values) {
      this.form.getFieldDecorator(key, {
        initialValue: values[key],
      });
    }
    this.form.setFieldsValue(values);
  }

  handleChangeDatePicker(date) {
    if (date) {
      this.setDataForm({
        deliveryOrderDate: moment(date, DATE_TIME_HOURS_DEFAULT_FORMAT),
      });
    } else
      this.setDataForm({
        deliveryOrderDate: undefined,
      });
  }
  // disabledDate(current) {
  //   return current < moment().subtract(1, "days").endOf("day");
  // }
  filterOption(input, option) {
    return (
      option.componentOptions.children[0].componentOptions.children[1].text
        .toLowerCase()
        .indexOf(input.toLowerCase()) >= 0
    );
  }

  beforeCreate(): void {
    this.form = this.$form.createForm(this, { name: "deliveryOrder" });
  }

  created() {
    this.mode = this.$route.meta.mode;
  }
  mounted() {
    this.getPickingList("");
    this.getSupplierExpedition(
      "supplierData.supplierType~*Ekspedisi*_AND_supplier~true_AND_active~true",
      "init"
    );
    this.getListAccessories();
    if (this.mode == Mode.EDIT) {
      this.disabledData = true;
      this.getDetailDeliveryOrder();
      this.idDo = this.$route.params.id;
    } else {
      this.idDo = "";
      this.disabledData = false;
    }

    const query = this.$route.query;
    if (query && query.from === "dashboard") {
      this.createFromDashboard(query.soId as string);
    }
  }

  get isFromDashboard(): boolean {
    const query = this.$route.query;
    return query && query.from === "dashboard";
  }

  createFromDashboard(soId: string | undefined): void {
    if (!soId) return;
    this.getDetailSo(soId);
    this.showNumber = "so";
  }

  checkDropdown(value) {
    if (
      value.supplierExpeditionId &&
      this.dataListSupplier.findIndex(
        item => item.id == value.supplierExpeditionId
      ) == -1
    ) {
      this.dataListSupplier.push({
        id: value.supplierExpeditionId,
        firstName: value.supplierExpeditionName,
      });
    }
  }
  getDetailDeliveryOrder() {
    this.dataListItems = [];
    return deliveryOrderServices
      .getDetailDeliveryOrder(this.$route.params.id)
      .then(data => {
        this.disabledPrint = false;
        this.journalNumber.name = data.journalNumber as string;
        this.journalNumber.id = data.journalId;
        this.checkDropdown(data);
        this.form.setFieldsValue({
          deliveryOrderNumber: data.documentNumber,
          deliveryOrderDate: data.date,
          supplierExpedition: data.supplierExpeditionId || "-",
          supplierAddressBillTo: data.supplierBillToAddress,
          truckNumber: data.truckNumber,
          driverName: data.driverName,
          journalNumber: data.journalNumber,
          customerName: data.customerName,
          customerAddressBillTo: data.customerBillToAddress,
          customerAddressShipTo: data.customerShipToAddress,
          phoneNumber: data.phoneNumber,
          customerPicName: data.customerPic,
          description: data.description,
          pickingListNumber: data.pickingListNo,
        });

        if (data.startRent && data.endRent)
          this.rentDate = [
            moment(data.startRent).format(),
            moment(data.endRent).format(),
          ];
        else this.rentDate = [];
        data.deliveryOrderLines.forEach((element, index) => {
          this.dataListItems.push({
            no: index + 1 + ".",
            productCode: element.productCode
              ? element.productCode
              : (element.unitCode as string),
            productName: element.productName as string,
            merk: element.merk,
            location: element.location,
            qty: currencyFormat(element.qty),
            serialNumber: element.serialNumber,
            type: element.type,
            // qtyAvailable: currencyFormat(element.qtyAvailable),
            uom: element.productUom as string,
            qtyPick: currencyFormat(element.qtyPick),
            key: index,
            listAccessories: element.listAccessories
              ? element.listAccessories[0]
              : "",
            modalListAccessories: element.listAccessories
              ? element.listAccessories
              : [],
            id: element.id,
            disabledInput: true,
            disabledCheckBox: true,
            customerLocation: element.customerLocation,
          });
          this.dataListItemsModal.forEach(item => {
            for (const iterator of element.listAccessories) {
              if (iterator === item.listAccessories) item.exist = true;
            }
            this.columnsTableModal[0].responsiveColCheckBox[0].disabled = true;
          });
        });
      });
  }
  getPickingList(value) {
    let params = {
      sorts: "createdDate:desc",
    } as RequestQueryParamsModel;
    if (value) params.search = `${value}`;
    this.loading.pickingList = true;
    return salesOrderServices
      .getPickingListCreateDo(params)
      .then(data => {
        data.data.forEach((dataObject, index) => (dataObject.key = index));
        this.dataListPickingList = data.data;
      })
      .finally(() => (this.loading.pickingList = false));
  }

  save(): void {
    let dataPost = {} as RequestDo;
    let dataDeliveryOrderLines = [] as DataDeliveryOrderLines[];
    this.form.validateFields((err, values) => {
      if (!err) {
        dataPost.customerBillToAddress = values.customerAddressBillTo;
        dataPost.customerPic = values.customerPicName;
        dataPost.customerShipToAddress = values.customerAddressShipTo;
        dataPost.date = moment(values.deliveryOrderDate).format();
        dataPost.pickingListId = this.dataTemp.pickingListId;
        dataPost.description = values.description;
        dataPost.driverName = values.driverName;
        dataPost.truckNumber = values.truckNumber;
        dataPost.phoneNumber = values.phoneNumber;
        dataPost.supplierBillToAddress = values.supplierAddressBillTo;
        dataPost.supplierExpeditionId = values.supplierExpedition;
        dataPost.customerId = this.dataTemp.customerId;
        dataPost.endRent = this.rentDate.length > 0 ? this.rentDate[1] : "";
        dataPost.startRent = this.rentDate.length > 0 ? this.rentDate[0] : "";
        if (this.showNumber === "so")
          dataPost.salesOrderId = this.dataTemp.dataId;
        else if (this.showNumber === "wo")
          dataPost.workOrderId = this.dataTemp.dataId;
        let dataDeliveryLines = {} as DataDeliveryOrderLines;

        let isValid = true;

        this.dataListItems.forEach(item => {
          if (changeCurrencytoNumeric(item.qtyPick) > 0) {
            dataDeliveryLines = {
              qtyPick: changeCurrencytoNumeric(item.qtyPick),
              qty: changeCurrencytoNumeric(item.qty),
              salesOrderLineId: item.id,
              listAccessories: item.modalListAccessories,
              customerLocation: item.customerLocation,
            };
            if (this.showNumber == "so")
              dataDeliveryLines.salesOrderLineId = item.id;
            else if (this.showNumber == "wo")
              dataDeliveryLines.workOrderLineId = item.id;
            else dataDeliveryLines.unitCode = item.productCode;
            dataDeliveryOrderLines.push(dataDeliveryLines);
          } else {
            isValid = false;
          }
        });
        if (!isValid) {
          this.showNotifError("lbl_required_column", {
            column: this.$t("lbl_qty_pick"),
          });
          return;
        }
        dataPost.deliveryOrderLines = dataDeliveryOrderLines;
        this.loading.isFormSubmitted = true;
        deliveryOrderServices
          .postDeliveryOrder(dataPost)
          .then(item => {
            this.$notification.success({
              description: Messages.CREATE_SUCCESS,
              message: "Success",
              duration: 30,
            });
            this.$notification.success({
              description: `DO number : ${item.documentNumber}`,
              message: "Success",
              duration: 30,
            });
            this.backNav();
            this.disabledPrint = false;
            this.idDo = item.id;
          })
          .catch(e => {
            this.disabledPrint = true;
            this.loading.isFormSubmitted = false;
            this.$notification.error({
              description: Messages.CREATE_FAIL,
              message: "Error",
              duration: 30,
            });

            this.$notification.error({
              description: e.details,
              message: "Error",
              duration: 30,
            });

            this.idDo = "";
            this.disabledData = false;
          })
          .finally(() => {
            this.loading.isFormSubmitted = false;
          });
      }
    });
  }

  handleAddNewAcc(): void {
    const newRow = {
      no: this.dataListItemsModal.length + 1 + ".",
      listAccessories: "",
      exist: false,
      key: this.dataListItemsModal.length + 1,
      disabledSelect: false,
    };
    this.dataListItemsModal.push(newRow);
  }

  handleSearchSelectTableModal(value, _key, colName: string): void {
    if (colName === "listAccessories") {
      this.inputAccessories = value;
    }
  }

  /**
   * handle add new acc from modal table
   */
  onAddNewTableModal(_key, colName: string): void {
    if (colName !== "listAccessories") return;
    const payload: CreateAccessories = {
      description: this.inputAccessories,
      name: this.inputAccessories,
    };
    this.createAccessories(payload);
  }

  getListAccessories() {
    salesOrderServices.getListAccessories().then(data => {
      data.forEach((item, index) => {
        this.dataInitListItemsModal.push({
          no: index + 1 + ".",
          listAccessories: item,
          exist: false,
          key: index,
          disabledSelect: true,
        });
      });
      this.setAccOptions(data);
    });
  }

  /**
   * set options for select options
   * modal table
   */
  setAccOptions(data: string[]): void {
    const colAcc = this.columnsTableModal.find(
      col => col.responsiveColSelectAddNew
    )?.responsiveColSelectAddNew;
    if (!colAcc) return;
    colAcc[0].options = data.map((item, i) => ({
      key: i,
      name: item,
      id: item,
    }));
  }

  /**
   * create new accessories for DO
   */
  createAccessories(payload: CreateAccessories): void {
    this.loading.createAcc = true;
    salesOrderServices
      .createAccessories(payload)
      .then(() => {
        this.showNotifSuccess("notif_create_success");
        this.updateListAccessories();
      })
      .catch(() => this.showNotifError("notif_create_fail"))
      .finally(() => (this.loading.createAcc = false));
  }

  updateListAccessories(): void {
    salesOrderServices.getListAccessories().then(data => {
      this.dataInitListItemsModal = data.map((item, index) => ({
        no: index + 1 + ".",
        listAccessories: item,
        exist: false,
        key: index,
        disabledSelect: true,
      }));
      this.dataListItemsModal = this.dataInitListItemsModal;
      this.setAccOptions(data);
    });
  }

  changeDropdown(value, type) {
    switch (type) {
      case "pickingList":
        this.dataListItems = [];
        this.dataListItemsModal.forEach(item => (item.exist = false));
        this.dataTemp.pickingListId = this.dataListPickingList.find(
          item => item["referenceId"] === value
        )?.id as string;
        if (
          this.dataListPickingList.find(item => item["referenceId"] === value)
            ?.transactionType == "Sales Order"
        ) {
          this.getDetailSo(value);
          this.showNumber = "so";
        } else if (
          this.dataListPickingList.find(item => item["referenceId"] === value)
            ?.transactionType == "Work Order"
        ) {
          this.showNumber = "wo";
          this.getDetailWorkOrder(value);
        } else {
          this.showNumber = "ic";
          this.getDetailPickingList(
            this.dataListPickingList.find(item => item["referenceId"] === value)
              ?.id
          );
        }
        break;
      case "supplier":
        this.dataListSupplierAddressBillTo = [];
        this.form.setFieldsValue({
          supplierAddressBillTo: "",
        });
        this.dataListSupplier
          .find(item => item.id === value)
          ?.addressDataList.forEach(element => {
            if (element.billTo) {
              this.dataListSupplierAddressBillTo.push(element);
            }
          });
        break;
      default:
        break;
    }
  }
  handleCheckBoxTable(checked, key, _nameColCheckbox, _value): void {
    this.dataListItemsModal[key].exist = checked;
  }
  clickJournal(id) {
    if (id) this.$router.push("/generaljournal/journal/detail/" + id);
  }
  getDetailPickingList(value) {
    this.dataListItems = [];
    this.dataListItemsModal.forEach(item => (item.exist = false));
    salesOrderServices.getDetailPickingList(value).then(data => {
      this.form.setFieldsValue({
        icNumber: data.transcationRefNo,
        customerName: data.customerName,
        customerAddressBillTo: data.customerBillTo,
        customerAddressShipTo: data.customerShipTo,
      });
      this.dataTemp.customerId = data.customerId;
      this.dataTemp.dataId = data.id;
      data.pickingListLines
        .filter(item => item.qtyPick < item.qty)
        .forEach((item, index) => {
          this.dataListItems.push({
            no: `${index + 1}.`,
            key: index,
            productCode: item.productCode,
            productName: item.productName,
            merk: item.merk,
            location: item.warehouseLocationDesc,
            qty: item.qty ? currencyFormat(item.qty) : "0.00",
            qtyPick: item.qtyPick
              ? currencyFormat(item.qtyPick)
              : currencyFormat(item.qty),
            serialNumber: item.serialNumber,
            type: item.type,
            // qtyAvailable: item.qtyAvailable
            //   ? currencyFormat(item.qtyAvailable)
            //   : "0.00",
            uom: item.uom,
            listAccessories: "",
            modalListAccessories: [],
            customerLocation: item.location,
          });
        });
    });
  }
  onSelectChange(selectedRowKeys) {
    this.selectedRowKeys = selectedRowKeys;
  }
  showConfirmation() {
    if (this.selectedRowKeys.length > 0) {
      this.$confirm({
        title: `Do you want to delete these items?`,
        content: `Total: ${this.selectedRowKeys.length} items will be deleted.`,
        onOk: () => {
          this.dataListItems = this.dataListItems.filter((data, _index) => {
            return !this.selectedRowKeys.includes(data.key);
          });
          this.dataListItems.forEach((data, index) => (data.key = index));
          this.dataListItems = this.dataListItems.slice();
          this.selectedRowKeys = [];
        },
        onCancel() {
          return;
        },
      });
    } else {
      this.$notification.error({
        message: "Error",
        description: "Select at least one row to delete",
      });
    }
  }
  getDetailSo(soId: string) {
    return salesOrderServices.getDetailSalesOrder(soId).then(res => {
      this.detailSO = res;
      this.form.setFieldsValue({
        salesOrderNumber: res.documentNumber,
        customerName: res.customerName,
        customerAddressBillTo: res.customerBillToAddress,
        customerAddressShipTo: res.customerShipToAddress,
      });
      this.dataTemp.customerId = res.customerId;
      this.dataTemp.dataId = res.id;
      this.dataTemp.pickingListId = res.pickingList[0].pickingListId;
      if (res.salesType === "Product Sale") {
        if (
          this.columnsTable.findIndex(
            item => item.dataIndex === "listAccessories"
          ) != -1
        ) {
          this.columnsTable.splice(
            this.columnsTable.findIndex(
              item => item.dataIndex === "listAccessories"
            ),
            1
          );
        }
      } else if (
        !this.columnsTable.some(
          dataSome => dataSome.dataIndex === "listAccessories"
        )
      ) {
        this.columnsTable.push({
          title: "List Accessories",
          dataIndex: "listAccessories",
          key: "listAccessories",
          width: 250,
          scopedSlots: { customRender: "buttonAdd" },
        });
      }
      if (res.startRent && res.endRent) {
        this.rentDate = [
          moment(res.startRent).format(),
          moment(res.endRent).format(),
        ];
      } else {
        this.rentDate = [];
      }
      let objDataPush = {} as DataListItems;
      let salesOrderLines: SalesOrderLineResponseDto[] = [];
      if (res.salesType === "Rent") {
        // nambah validasi
        // kalau salesTypenya rent di salesOrderLine yang product jgn ditampilin (ketika asset idnya null tapi product idnya tidak null)
        salesOrderLines = res.salesOrderLines.filter(
          item => item.qtyOutstanding && !item.productId && item.assetId
        );
      } else {
        salesOrderLines = res.salesOrderLines.filter(
          item => item.qtyOutstanding
        );
      }
      salesOrderLines.forEach((element, index) => {
        objDataPush = {
          no: index + 1 + ".",
          productCode: element.productCode || element.unitCode,
          productName: element.productName,
          merk: element.merk,
          location: element.location,
          qty: currencyFormat(element.qtyOutstanding),
          serialNumber: element.serialNumber,
          type: element.type,
          uom: element.uomName,
          qtyPick: currencyFormat(element.qtyOutstanding),
          key: index,
          listAccessories: "",
          modalListAccessories: [],
          id: element.id,
          customerLocation: element.customerLocation,
        };
        this.dataListItems.push(objDataPush);
      });
    });
  }
  getDetailWorkOrder(id) {
    logisticServices.getDetailWorkOrder(id).then((res: any) => {
      this.form.setFieldsValue({
        workOrderNumber: res.documentNumber,
        customerName: res.customerName,
        customerAddressBillTo: res.customerAddress,
        customerAddressShipTo: res.customerAddress,
      });
      this.rentDate = [];
      this.dataTemp.customerId = res.customerId;
      this.dataTemp.dataId = res.workOrderId;
      if (res.startRent != null && res.endRent != null) {
        this.rentDate = [
          moment(res.startRent).format(),
          moment(res.endRent).format(),
        ];
      }
      res.workOrderLines.forEach((element, index) => {
        this.dataListItems.push({
          no: index + 1 + ".",
          productCode: element.productCode
            ? element.productCode
            : element.unitCode,
          productName: element.productName,
          merk: element.merk,
          location: element.productLocationDesc,
          qty: currencyFormat(element.qtyWorkOrder),
          // qtyAvailable: currencyFormat(element.qtyAvailable),
          serialNumber: element.serialNumber,
          type: element.type,
          uom: element.productUomName,
          qtyPick: currencyFormat(element.qtyWorkOrder),
          key: index,
          listAccessories: "",
          modalListAccessories: [],
          id: element.id,
          customerLocation: res.customerLocation,
        });
      });
    });
  }
  getSupplierExpedition(query, value) {
    let params: RequestQueryParamsModel = {
      limit: 10,
      page: 0,
    };
    if (value && query) params.search = query;
    this.loading.supplier = true;
    return contactServices
      .listContactData(params)
      .then(response => (this.dataListSupplier = response.data))
      .finally(() => (this.loading.supplier = false));
  }
}
