













































































































































import { removeDuplicate } from "@/helpers/common";
import MNotificationVue from "@/mixins/MNotification.vue";
import { Mode } from "@/models/enums/global.enum";
import { Messages } from "@/models/enums/messages.enum";
import {
  CreateUserAdditionalPrivilegeDto,
  CreateUserDto,
  DetailUserDto,
  UpdateUserDto,
  UserMenuState,
} from "@/models/interface/user";
import { userServices } from "@/services/user.services";
import { LabelInValue } from "@/types";
import {
  checkPasswordStrength,
  emailValidator,
} from "@/validator/globalvalidator";
import Vue from "vue";

export default Vue.extend({
  name: "CreateUser",
  mixins: [MNotificationVue],
  components: {
    TableRole: () => import("./components/user/TableRole.vue"),
    RoleSelect: () => import("@/components/custom/select/RoleSelect.vue"),
    EmployeeSelect: () =>
      import("@/components/custom/select/EmployeeSelect.vue"),
    SelectBranch: () => import("@/components/custom/select/SelectBranch.vue"),
  },
  data() {
    return {
      deleteAddPrivilegeIds: [] as string[],
      loading: {
        table: false,
        submit: false,
      },
      mode: Mode.CREATE as string,
      idUser: "" as string,
      columnTabPane: ["Additional Privilege"] as string[],
      columnIdTabPane: [] as string[],
      selectedRowKeys: [] as number[],
      activeTabPane: "Additional Privilege" as string,
      dataSourceTabPane: {} as Record<string, UserMenuState[]>,
      formRules: {
        employee: {
          label: "lbl_employee",
          name: "employee",
          placeholder: "lbl_name_placeholder",
          decorator: [
            "employee",
            {
              rules: [
                {
                  type: "object",
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        username: {
          label: "lbl_signin_username",
          name: "username",
          placeholder: "lbl_signin_username_placeholder",
          decorator: [
            "username",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        truckingUsername: {
          label: "lbl_trucking_username",
          name: "truckingUsername",
          placeholder: "lbl_trucking_username_placeholder",
          decorator: [
            "truckingUsername",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        password: {
          label: "lbl_signin_password",
          name: "password",
          placeholder: "lbl_signin_password_placeholder",
          decorator: [
            "password",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
                {
                  validator: checkPasswordStrength,
                },
              ],
            },
          ],
        },
        confirmpassword: {
          label: "lbl_confirm_password",
          name: "confirmpassword",
          placeholder: "lbl_confirm_password_placeholder",
          decorator: [
            "confirmpassword",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
                {
                  validator: this.customValidator,
                },
              ],
            },
          ],
        },
        email: {
          label: "lbl_email",
          name: "email",
          placeholder: "lbl_email_placeholder",
          decorator: [
            "email",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
                {
                  validator: emailValidator,
                },
              ],
            },
          ],
        },
        isActive: {
          label: "lbl_active",
          decorator: [
            "isActive",
            {
              valuePropName: "checked",
              initialValue: true,
            },
          ],
        },
        role: {
          label: "lbl_role",
          name: "role",
          placeholder: "lbl_role_placeholder",
          decorator: [
            "role",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                  type: "array",
                },
              ],
            },
          ],
        },
        isAllBranch: {
          label: "lbl_all_branch",
          decorator: [
            "isAllBranch",
            {
              valuePropName: "checked",
              initialValue: true,
            },
          ],
        },
        branches: {
          label: "lbl_branch",
          name: "branches",
          placeholder: "lbl_branch",
          decorator: ["branches"],
        },
      },
      dataSource: [] as UserMenuState[],
      detailUser: {} as DetailUserDto,
      disableBranch: true as boolean,
    };
  },
  computed: {
    formItemLayout() {
      return {
        labelCol: { span: 8 },
        wrapperCol: { span: 12 },
      };
    },
  },
  beforeCreate(): void {
    this.form = this.$form.createForm(this, { name: "addUserForm" });
  },
  created() {
    // set Mode
    this.mode = this.$route.meta.mode;
    this.idUser = this.$route.params.id ?? "";
  },
  mounted() {
    this.initData();
  },
  methods: {
    customValidator(_rule, value, callback) {
      if (this.form.getFieldValue("password") !== value) {
        callback(this.$t("lbl_password_doesnt_match").toString());
      } else {
        callback();
      }
    },
    onChangeRole(value: LabelInValue[]): void {
      if (value.length < 1) {
        this.dataSourceTabPane = {
          "Additional Privilege":
            this.dataSourceTabPane["Additional Privilege"],
        };
      }

      const roleIds: string[] = [];
      const roleNames: string[] = [];
      value.forEach(data => {
        roleIds.push(data.key);
        roleNames.push(data.label);
      });

      if (roleNames.length > 0) {
        Object.keys(this.dataSourceTabPane).forEach(keyDataSource => {
          if (
            !roleNames.includes(keyDataSource) &&
            keyDataSource !== "Additional Privilege"
          ) {
            delete this.dataSourceTabPane[keyDataSource];
          }
        });
      }

      this.columnIdTabPane = roleIds;
      this.columnTabPane = [
        ...roleNames,
        this.columnTabPane[this.columnTabPane.length - 1],
      ];

      this.columnTabPane.forEach((data, index) => {
        if (this.dataSourceTabPane[data] === undefined) {
          this.loading.table = true;
          userServices
            .detailRole(this.columnIdTabPane[index])
            .then(response => {
              const newArr: UserMenuState[] = [];
              response.menus.forEach((menu, i) => {
                newArr.push({
                  menu: { label: menu.name, key: menu.id },
                  description: menu.description,
                  create: menu.create,
                  read: menu.read,
                  update: menu.update,
                  delete: menu.delete,
                  key: i,
                  disabled: true,
                  createId: null,
                  deleteId: null,
                  id: null,
                  isSearchMenu: false,
                  menuLoading: false,
                  menuOptions: [],
                  readId: null,
                  updateId: null,
                });
              });
              this.$set(this.dataSourceTabPane, data, newArr);
            })
            .finally(() => (this.loading.table = false));
        }
      });
    },
    onTabChange(key) {
      this.activeTabPane = key;
      this.dataSource = this.dataSourceTabPane[key].slice();
    },
    onChangeIsAllBranch(isAllBranch: boolean): void {
      this.disableBranch = isAllBranch;

      if (isAllBranch) {
        this.form.setFieldsValue({ branches: [] });
      }
    },
    submitForm(e: Event): void {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (err) {
          this.showNotifError("lbl_validation_required_error");
          return;
        }

        const listMenuId: string[] = [];
        const checkDataHaveIdNoCrud: boolean[] = [];
        const additionalPrivileges: CreateUserAdditionalPrivilegeDto[] = [];

        const hasMenu: UserMenuState[] = this.dataSourceTabPane[
          "Additional Privilege"
        ].filter(data => data.menu);

        hasMenu.forEach(data => {
          if (data.menu) {
            listMenuId.push(data.menu?.key);
          }

          // check have idMenu && no CRUD
          if (!data.create && !data.read && !data.update && !data.delete) {
            checkDataHaveIdNoCrud.push(true);
          }
          additionalPrivileges.push({
            idMenu: data.menu?.key ?? "",
            create: data.create ? data.createId : null, // deleted || null
            read: data.read ? data.readId : null,
            update: data.update ? data.updateId : null,
            delete: data.delete ? data.deleteId : null,
            id: data.id ?? "",
          });
        });

        if (checkDataHaveIdNoCrud.includes(true)) {
          this.showNotifError("notif_required_select_privilege");
          return;
        }

        const checkDuplicateData = listMenuId.some((element, index) => {
          return listMenuId.indexOf(element) !== index;
        });

        if (checkDuplicateData) {
          this.showNotifError("notif_duplicate_additional_privilege");
          return;
        }

        // with password
        const payloadCreate: CreateUserDto = {
          employeeId: values.employee.key,
          username: values["username"],
          password: values["password"],
          email: values["email"],
          active: values.isActive,
          truckingUsername: values["truckingUsername"],
          roleIds: values.role.map((item: LabelInValue) => item.key),
          additionalPrivilege: additionalPrivileges,
          isAllBranch: values.isAllBranch,
          branchIds: values.branches?.map(branch => branch.key) ?? [],
        };

        // without password
        const payloadUpdate: UpdateUserDto = {
          ...payloadCreate,
          deleteAddPrivilegeIds: this.deleteAddPrivilegeIds,
        };

        this.loading.submit = true;
        if (this.mode === Mode.CREATE) this.createUser(payloadCreate);
        else if (this.mode === Mode.EDIT)
          this.updateUser(this.idUser, payloadUpdate);
      });
    },
    resetConfPassword(): void {
      this.form.setFieldsValue({
        confirmpassword: "",
      });
    },
    initData(): void {
      if (this.mode === Mode.CREATE) {
        this.initAdditionalPrivilege();
        this.disableBranch = true;
      } else {
        this.disableBranch = false;
        this.getDetailUser(this.idUser);
      }
    },
    initAdditionalPrivilege(): void {
      this.dataSourceTabPane = {
        "Additional Privilege": [
          {
            menu: undefined,
            description: null,
            create: false,
            read: false,
            update: false,
            delete: false,
            key: 0,
            disabled: false,
            createId: null,
            deleteId: null,
            id: "",
            isSearchMenu: false,
            menuLoading: false,
            menuOptions: [],
            readId: null,
            updateId: null,
          },
        ],
      };
      this.dataSource = this.dataSourceTabPane[this.activeTabPane];
    },
    getDetailUser(userId: string): void {
      this.loading.table = true;
      userServices
        .getDetailUser(userId)
        .then(data => {
          this.detailUser = data;
          // set additional privileges kalau lengthnya 0
          if (this.detailUser.additionalPrivileges.length < 1) {
            this.initAdditionalPrivilege();
          } else {
            this.dataSourceTabPane = {
              "Additional Privilege": [],
            };
            // set additional privileges kalau lengthnya > 0
            this.detailUser.additionalPrivileges.forEach((item, index) => {
              this.dataSourceTabPane["Additional Privilege"].push({
                menu: {
                  label: item.menuName,
                  key: item.menuId,
                },
                description: item.menuDesc,
                create: !!item.create,
                read: !!item.read,
                update: !!item.update,
                delete: !!item.delete,
                createId: item.create,
                readId: item.read,
                updateId: item.update,
                deleteId: item.delete,
                key: index,
                disabled: false,
                id: item.id || "",
                isSearchMenu: false,
                menuLoading: false,
                menuOptions: [],
              });
            });
          }

          const joinArrayIdName: LabelInValue[] = data.roles.map(role => {
            return { label: role.name, key: role.id };
          });

          this.form.setFieldsValue({
            email: data.email,
            employee: {
              key: data.employeeId,
              label: data.employeeName,
            },
            role: data.roles.map(item => ({
              label: item.name,
              key: item.id,
            })),
            username: data.username,
            isActive: data.active,
            truckingUsername: data.truckingUsername,
            isAllBranch: false,
            branches:
              data.branchList?.map(branch => ({
                key: branch.branchId,
                label: branch.branchName,
              })) ?? [],
          });
          this.dataSource = this.dataSourceTabPane[this.activeTabPane];

          // trigger for create related tab based on user roles
          this.onChangeRole(joinArrayIdName);
        })
        .finally(() => (this.loading.table = false));
    },
    onChangeRoleTable(param: {
      dataSource: UserMenuState[];
      deletedIds: string[];
    }): void {
      const { dataSource: newSource, deletedIds } = param;
      const { deleteAddPrivilegeIds } = this;
      this.dataSource = [...newSource];
      this.dataSourceTabPane[this.activeTabPane] = [...this.dataSource];
      this.deleteAddPrivilegeIds = removeDuplicate([
        ...deleteAddPrivilegeIds,
        ...deletedIds,
      ]);
    },
    createUser(payload: CreateUserDto): void {
      userServices
        .create(payload)
        .then(() => {
          this.showNotifSuccess("notif_create_success");
          this.form.resetFields();
          this.$router.push({ name: "user.list" });
        })
        .finally(() => (this.loading.submit = false));
    },
    updateUser(userId: string, payload: UpdateUserDto): void {
      userServices
        .update(payload, userId)
        .then(() => {
          this.showNotifSuccess("notif_update_success");
          this.form.resetFields();
          this.$router.push({ name: "user.list" });
        })
        .finally(() => (this.loading.submit = false));
    },
    handleBack() {
      this.$router.push({ name: "user.list" });
    },
  },
});
